import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const item = {
    visible: { opacity: 1, y: 0, transition: { ease: "easeIn", duration: 0.5 } },
    hidden: { opacity: 0, y: 150 },
}

const whyanimate = {
    visible: { opacity: 1, },
    hidden: { opacity: 0 }
}

const container = {
    visible: {
        transition: {
            staggerChildren: 0.5,
            ease: "easeInOut",
        }

    }
}


const Cards = (props) => {
    return (
        <motion.div variants={container} initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
            className="flex flex-col md:flex-row flex-wrap xl:px-12 2xl:px-12 justify-center mt-10">
            {props.solution.map((sol, i) => (
                <motion.div variants={item}
                    className="md:w-1/2 xl:w-1/4 2xl:w-1/4 w-full px-8 py-3 hover:bg-white hover:shadow transition-all duration-300 group"
                    key={i}>
                    <Link to={`/products/${sol.slug}`} className=" w-full">
                        <div className="flex flex-col  items-center text-center  text-wrap">
                            <picture className="object-cover">
                                <source srcSet={sol.featured_image.square_jpg} type="image/webp" />
                                <source srcSet={sol.featured_image.square_webp} type="image/jpeg" />
                                <img className="object-cover w-full " src={sol.featured_image.banner} alt="" />
                            </picture>
                            <div className="md:text-left font-paragraph mt-2 ">
                                <h1 className=" font-bold lg:text-lg xl:text-xl group-hover:text-primary transition-colors">{sol.name}</h1>
                                <p className="clampstyle pt-2 text-left md:text-center xl:text-left mb-8"
                                    dangerouslySetInnerHTML={{ __html: sol.intro }} />
                            </div>
                        </div>
                    </Link>


                </motion.div>

            ))
            }
        </motion.div>
    )
}
export default Cards