import React, {Component} from "react";
import Slider from "react-slick";

function SamplePrevArrow(props) {
    const {className, onClick} = props;
    return (
        <div className=" top-0 absolute z-10 right-12 hover:scale-x-105 origin-right transition-all hover:text-primary hover:right-14 cursor-pointer"
             onClick={onClick}>
            <span className="material-symbols-rounded text-3xl">
                west
            </span>
        </div>
    );
}

function SampleNextArrow(props) {
    const {className, onClick} = props;
    return (
        <div className=" top-0 absolute z-10 right-2 hover:scale-x-105 origin-left transition-all hover:text-primary hover:right-0 cursor-pointer"
             onClick={onClick}>
            <span className="material-symbols-rounded text-3xl">
                east
            </span>
        </div>
    );
}


export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };

        return (
            <div>
                <Slider className="pt-8" {...settings}>
                    {this.props.slider.map((img, i) => (
                        <div key={i}>
                            <div className=" text-center h-full px-2">

                                <picture className="">
                                    <source srcSet={img.image.webp_full} type="image/webp"/>
                                    <source srcSet={img.image.full} type="image/jpeg"/>
                                    <source media="(min-width:768px)" srcSet={img.image.webp_full} type="image/webp"/>
                                    <source media="(min-width:768px)" srcSet={img.image.full} type="image/jpeg"/>
                                    <img className="w-auto object-cover"
                                         src={img.image.full} alt=""/>
                                </picture>


                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        )
            ;
    }
    }

