import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Header from "./components/Header";
import Footer from './components/Footer';
import Product from "./pages/Product";
import Headroom from "react-headroom";
import AboutUs from "./pages/AboutUs";
import Gallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import Test from "./pages/Test";
import api from "./api";


export default function App() {
    const [menu, setMenu] = useState(null);
    const [solutions, setSolutions] = useState(null);

    async function fetchMenuData() {
        const response = await api.get("/api/menu_api/");
        response.data.map(async (submenu) => {
            if (submenu.name === "top") {
                await setMenu(submenu);
            } else if (submenu.name === "solutions") {
                await setSolutions(submenu);
            }
        });
    }

    useEffect(() => {
        fetchMenuData();
    }, []);
    return (
        <div className="relative font-paragraph bg-background">
            <Router>
                <Headroom style={{ zIndex: 999 }}>
                    {
                        solutions !== null && <Header menu={menu} solutions={solutions} />
                    }
                </Headroom>
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/products/:product_slug" element={<Product />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/test" element={<Test />} />


                </Routes>
                {
                    solutions !== null && <Footer solutions={solutions} />
                }
            </Router>

        </div>
    );
}
