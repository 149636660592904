import mark from '../assets/images/mlmark.svg'
import {useEffect, useState} from "react";
import api from "../api";
import {useParams} from "react-router-dom";
import {contentProcessor, Loader, tabsProcessor} from "../utils";
import ContactForm from "../components/ContactForm";
import {AnimatePresence, motion} from "framer-motion";


function Product() {
    const [product, setProduct] = useState(null);
    let {category_slug, product_slug} = useParams();

    async function fetchProductData() {
        await setProduct(null);
        const response = await api.get(`/api/mark_plus/products/${product_slug}/`);
        await setProduct(response.data);
    }

    const title = {
        visible: {opacity: 1, y: 0},
        hidden: {opacity: 0, y: 150},
    }


     const fadeout ={
        visible: {opacity:1,},
        hidden:{opacity:0}
    }
    const items = {
        visible: {opacity: 1, y: 0, transition: {ease: "easeIn", duration: 0.5}},
        hidden: {opacity: 0, y: 150},
    }

    const container = {
        visible: {
            transition: {
                staggerChildren: 0.5,
                ease: "easeInOut",
            }

        }
    }
    useEffect(() => {
        fetchProductData();
    }, [product_slug]);
    console.log(product)
    return (
        <div className="w-full">
            {
                product == null ? <Loader/> : <AnimatePresence>
                    <motion.div initial={{scale: 0.95, opacity: 0}}
                                animate={{scale: 1, opacity: 1}}
                                exit={{scale: 0.95, opacity: 0}}
                                className="origin-top w-full">
                        <div className="w-full relative">
                            <picture className="w-full">
                                <source className="w-full object-cover" type="image/webp"
                                        srcSet={product.featured_image.webp_banner}/>
                                <source className="w-full object-cover" type="image/jpeg"
                                        srcSet={product.featured_image.full}/>
                                <img className="w-full object-cover"
                                     src={product.featured_image.full}
                                     alt=""/>
                            </picture>
                            <div
                                className="absolute inset-0 w-full h-full bg-white bg-opacity-20 py-3 lg:py-12 px-3 md:px-12 lg:px-16 2xl:px-36 pt-28 flex justify-center items-center z-20">
                                <motion.h3
                                    variants={title}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{duration: 0.8, delay:1}}
                                    className="text-2xl lg:text-3xl 2xl:text-5xl text-secondary px-4 py-2 bg-white bg-opacity-25 rounded-md font-primary font-bold lg:tracking-wide ">{product.name}</motion.h3>

                            </div>
                        </div>
                        <div
                            className="w-full flex flex-col py-4 lg:py-10 lg:pb-20 items-center px-4 md:px-8 lg:px-12 2xl:px-32">
                            <motion.div
                                variants={title}
                                initial="hidden"
                                animate="visible"
                                transition={{duration: 0.8}}
                                className="w-full flex flex-wrap mt-3 lg:mt-10">
                                <div
                                    className="w-full md:w-1/2 lg:w-1/3 lg:px-20 flex flex-col lg:flex-row justify-start">
                                    <div className="w-12 lg:w-20">
                                        <img className="w-full" src={mark} alt=""/>
                                    </div>
                                    <h5 className="text-xl lg:text-2xl 2xl:text-4xl font-primary font-bold mb-4 text-secondary mt-3">
                                        Product Description</h5></div>
                                <div className="flex-1 lg:columns-2 lg:text-lg">
                                    <p className="max-w-none prose prose-base lg:prose-lg leading-normal "
                                       dangerouslySetInnerHTML={{__html: product.description}}/>
                                </div>
                            </motion.div>
                        </div>
                        <div className="w-full flex flex-col py-4 items-center ">
                            <motion.div
                                variants={container} initial="hidden"
                                whileInView="visible"
                                viewport={{once: false}}
                                className="w-full flex flex-col pt-4 lg:pt-8 2xl:pt-10 items-center">
                                <div className="w-full px-4 md:px-8 lg:px-12 2xl:px-32 py-5 bg-white">
                                    {
                                        product.content.map((item, i) => (
                                            <motion.div variants={items}>
                                                {contentProcessor(item, i)}
                                            </motion.div>)
                                        )}
                                </div>
                                <div
                                            className="w-full flex pt-8 bg-white">
                                    {
                                        tabsProcessor(product.tabs)
                                    }
                                </div>
                            </motion.div>
                        </div>
                        <ContactForm/>
                    </motion.div>
                </AnimatePresence>
            }
        </div>
    );
}

export default Product;