import {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import api from "../api";
import {AnimatePresence, motion} from "framer-motion";


export default function ContactUs() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const onSubmit = async data => {
        await setLoading(true);
        await setSuccess(false);
        await setError(false);
        try{
            const response = await api.post("https://theroguepixxel.com/waapi/contact_submission", {
                uid: data.uid,
                name: data.name,
                phone: `91${data.phone}`,
                email: data.email,
                purpose: data.purpose,
                message: data.message
            });
            await setLoading(false);
            if (response.status === 200){
                await setSuccess(true);
                await setError(false);
            }
        }catch (e){
            await setLoading(false);
            await setSuccess(false);
            await setError(true);
        }
    };

    useEffect(() => {
    }, []);
    return (
        <AnimatePresence>
        <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="origin-top w-full">

            <div className="h-[75vh] pt-14 md:pt-28 lg:pt-24 bg-white">
                <iframe
                    className="w-full h-full"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5027.999480786692!2d85.30096604214239!3d23.349846078065163!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf2e3be8c02a885ba!2sAbhishek%20metals!5e0!3m2!1sen!2sin!4v1660845219254!5m2!1sen!2sin"
                    allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="py-4 lg:py-10 2xl:py-20 items-center px-4 md:px-8 lg:px-12 2xl:px-32"><h3
                className="text-2xl lg:text-3xl 2xl:text-5xl text-primary font-primary font-bold tracking-wide text-center">Contact
                Us</h3>
                <div
                    className="w-full flex flex-wrap mt-16 bg-white rounded-lg p-2 lg:p-4 border border-dark w-full lg:w-4/5 mx-auto">
                    <div
                        className="flex-1 px-4 md:px-6 lg:px-8 py-4 bg-gradient-to-l to-cyan-500 from-secondary rounded-lg text-white flex flex-col">
                        <h5 className="font-primary text-2xl xl:text-3xl font-bold mb-3 2xl:mb-5">Our Offices</h5>
                        <div className="w-full flex flex-col justify-between flex-1">
                            <div className=" flex flex-col lg:px-3">
                                <div className="w-full border-b border-b-[#acacac] flex">
                                    <div
                                        className="w-1/5 flex flex-col items-center justify-center py-2 xl:py-5 border-r border-r-[#acacac]">
                                        <span className="material-icons-outlined text-white mb-1 text-xl">science</span>
                                        <p className="text-white text-sm text-center">R & D Office</p></div>
                                    <div className="flex-1 border-l border-l-[#dddddd] px-4 flex items-center"><p
                                        className="text-sm lg:text-base text-white">Jangamwadi Road, Nanded Mumbai
                                        (Maharastra) - 431602</p></div>
                                </div>
                                <div className="w-full border-t border-t-[#dddddd] border-b border-b-[#acacac] flex">
                                    <div
                                        className="w-1/5 flex flex-col items-center justify-center py-2 xl:py-4 border-r border-r-[#acacac]">
                                        <span
                                            className="material-icons-outlined text-white mb-1 text-xl">work_outline</span>
                                        <p className="text-white text-sm text-center">Corporate Office</p></div>
                                    <div className="flex-1 border-l border-l-[#dddddd] px-4 flex items-center"><p
                                        className="text-sm lg:text-base text-white">6 Namrata Industrial Estate, Near
                                        Nagarvel Hanuman Mandir, Amrivadi, Ahmedabad (Gujrat) - 380026</p></div>
                                </div>
                                <div className="w-full flex border-t border-t-[#dddddd]">
                                    <div
                                        className="w-1/5 flex flex-col items-center justify-center py-2 xl:py-4 border-r border-r-[#acacac]">
                                        <span className="material-icons-outlined text-white mb-1 text-xl">store</span><p
                                        className="text-white text-sm text-center">Branch Office</p></div>
                                    <div className="flex-1 border-l border-l-[#dddddd] px-4 flex items-center"><p
                                        className="text-sm lg:text-base text-white">Abhishek Metals, Rd. No.-5, Opp.
                                        Ashok Nagar, Ranchi (Jharkhand) - 834002</p></div>
                                </div>
                            </div>
                            <div className="w-full flex flex-wrap justify-between mt-4 2xl:mt-8"><a
                                className="text-white mb-1.5 hover:text-accent transition-colors text-sm"
                                href="tel:+918102084477">(+91)-81020 84477</a><a
                                className="text-white mb-1.5 hover:text-accent transition-colors text-sm"
                                href="tel:+919334593611">(+91)-93345 93611</a><a
                                className="text-white mb-1.5 hover:text-accent transition-colors text-sm"
                                href="mailto:mlmarkplus900@gmail.com">mlmarkplus900@gmail.com</a></div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 px-4 md:px-6 lg:px-6 py-4 flex flex-col"><h5
                        className="font-primary text-2xl xl:text-3xl font-bold mb-3 2xl:mb-5">Leave us a Message or your
                        Query</h5>
                        <form onSubmit={handleSubmit(onSubmit)} className="lg:pl-6 my-auto">
                            <div className="flex flex-wrap">
                                <input
                                    {...register("uid", { required: true })}
                                    className="w-full relative border border-secondary rounded-sm transition-colors focus-within:text-dark focus-within:border-accent items-center mt-1 group px-4 py-2 text-base lg:text-lg"
                                    name="uid" type="text" required="" hidden={true} value="mark_plus"/>
                                <div className="w-full lg:w-1/2 relative input_group md:pr-4 mt-3 lg:mt-1"><input
                                    {...register("name", { required: true })}
                                    className="w-full relative border border-secondary rounded-sm transition-colors focus-within:text-dark focus-within:border-accent items-center mt-1 group px-4 py-2 text-base lg:text-lg"
                                    placeholder="Enter your Name" name="name" type="text" required=""/><label
                                    className="bg-white text-base mt-1 px-2 text-left block absolute top-0 left-2 -translate-y-1/2 text-dark text-opacity-80">Full
                                    Name</label>
                                    {errors.name && <span className="text-red-600 text-xs">Please enter your name.</span>}
                                </div>
                                <div className="w-full lg:w-1/2 relative input_group md:pr-4 mt-3 lg:mt-1"><input
                                    {...register("phone", { required: true })}
                                    className="w-full relative border border-secondary rounded-sm transition-colors focus-within:text-dark focus-within:border-accent items-center mt-1 group px-4 py-2 text-base lg:text-lg"
                                    placeholder="Enter your Mobile Number" name="phone" type="tel" maxLength={10} required=""/><label
                                    className="bg-white text-base mt-1 px-2 text-left block absolute top-0 left-2 -translate-y-1/2 text-dark text-opacity-80">Phone
                                    Number</label>
                                    {errors.phone && <span className="text-red-600 text-xs">Please enter a valid phone Number.</span>}
                                </div>
                            </div>
                            <div className="flex flex-wrap mt-4 2xl:mt-6">
                                <div className="w-full lg:w-1/2 relative input_group md:pr-4 mt-3 lg:mt-1"><input
                                    {...register("email", { required: true })}
                                    className="w-full relative border border-secondary rounded-sm transition-colors focus-within:text-dark focus-within:border-accent items-center mt-1 group px-4 py-2 text-base lg:text-lg"
                                    placeholder="Enter your E-mail address" name="email" type="email"
                                    required=""/><label
                                    className="bg-white text-base mt-1 px-2 text-left block absolute top-0 left-2 -translate-y-1/2 text-dark text-opacity-80">E-mail</label>
                                    {errors.email && <span className="text-red-600 text-xs">Please enter a valid E-Mail.</span>}
                                </div>
                                <div className="w-full lg:w-1/2 relative input_group md:pr-4 mt-3 lg:mt-1"><select
                                    {...register("purpose", { required: true })}
                                    className="w-full text-base md:text-lg relative bg-transparent border border-secondary rounded-sm transition-colors focus-within:border-accent items-center mt-1 group px-4 py-2 pr-6 overflow-ellipsis appearance-none"
                                    name="purpose">
                                    <option className=" text-xs md:text-lg text-[#9ca3af]" value="null" disabled="">What
                                        is your query regarding?
                                    </option>
                                    <option className="text-xs md:text-lg mt-1" value="Product Purchase">Product Purchase</option>
                                    <option className="text-xs md:text-lg mt-1" value="Service Related">Service Related</option>
                                    <option className="text-xs md:text-lg mt-1" value="Business Proposition">Business Proposition</option>
                                </select><label
                                    className="bg-white text-base mt-1 px-2 text-left block absolute top-0 left-2 -translate-y-1/2 text-dark text-opacity-80">Purpose</label>
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-6 flex items-center text-dark opacity-60 mt-1">
                                        <svg className="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                        </svg>
                                    </div>
                                    {errors.purpose && <span className="text-red-600 text-xs">Please choose the Purpose.</span>}
                                </div>
                            </div>
                            <div className="w-full relative input_group md:pr-4 mt-3 lg:mt-4 2xl:mt-6"><textarea
                                {...register("message", { required: true })}
                                className="w-full relative border border-secondary rounded-sm transition-colors focus-within:text-dark focus-within:border-accent items-center mt-1 group px-4 py-2 text-base lg:text-lg"
                                name="message" id="" rows="5" cols="30" placeholder="Enter your Message." maxLength={900}></textarea><label
                                className="bg-white text-base mt-1 px-2 text-left block absolute top-0 left-2 -translate-y-1/2 text-dark text-opacity-80">Message</label>
                                {errors.message && <span className="text-red-600 text-xs">Please enter your Message in maximum of 900 Characters.</span>}
                            </div>
                            <div className="w-full relative input_group md:pr-4 mt-4">
                                <button className="disabled:opacity-75 disabled:cursor-not-allowed bg-accent text-white flex items-center justify-center font-bold text-xl px-6 py-2 rounded" type="submit" disabled={loading || success}>
                                    {loading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>}Submit
                                </button>
                                <div className="my-2 w-full text-center">
                                    {
                                        success && <div
                                            className="inline-flex items-center bg-green-600 border-l-4 border-green-700 py-2 px-3 shadow-md my-2">
                                            <div className="text-green-500 rounded-full bg-white mr-3">
                                                <svg width="1.8em" height="1.8em" viewBox="0 0 16 16" className="bi bi-check"
                                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                                </svg>
                                            </div>
                                            <div className="text-white max-w-xs ">
                                                We have received you message, and will get back to you soon.
                                            </div>
                                        </div>
                                    }
                                    {
                                        error && <div
                                            className="inline-flex items-center bg-red-600 border-l-4 border-red-700 py-2 px-3 shadow-md my-2">
                                            <div className="text-red-500 rounded-full bg-white mr-3">
                                                <svg width="1.8em" height="1.8em" viewBox="0 0 16 16"
                                                     className="bi bi-exclamation" fill="currentColor"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                                </svg>
                                            </div>
                                            <div className="text-white max-w-xs ">
                                                There was an error sending your message, please check your connection and try again.
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </motion.div>
        </AnimatePresence>);
}