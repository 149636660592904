import mark from '../assets/images/logo.svg'
import {Link} from "react-router-dom";

const Footer = (props) => {
    return (
        <>
            <div
                className="w-full flex flex-col py-4 xl:py-6 items-center px-4 md:px-8 lg:px-12 2xl:px-32 bg-white border-t">
                <div className="w-full grid grid-cols-3 md:flex">
                    <div className="flex flex-col h-full justify-evenly md:flex-1">
                        <Link to={"/"} className="text-sm lg:text-lg hover:text-primary transition-colors">Home</Link>
                        <Link to={"/about-us"} className="text-sm lg:text-lg hover:text-primary transition-colors">About Us</Link>
                        <Link to={"/contact"} className="text-sm lg:text-lg hover:text-primary transition-colors">Contact Us</Link>
                    </div>
                    <div className="flex flex-col pl-1 md:pl-2 space-y-2 md:flex-1">
                        {
                            props.solutions.solutions.map((solution, i) => <Link key={i} className="text-sm hover:text-primary transition-colors lg:text-base font-medium "
                                      to={`/products/${solution.slug}`}>{solution.name}</Link>)
                        }
                    </div>
                    <div className="flex h-full justify-center items-center md:flex-1 md:self-center">
                        <div>
                            <img className="h-16" src={mark} alt=""/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-full flex px-4 md:px-8 lg:px-12 2xl:px-32 py-1 justify-between"><p
                className="text-xs flex items-center"><span
                className="material-icons-outlined text-xs">copyright</span>&nbsp;2024, ML Mark Plus</p><p
                className="text-xs flex items-center">Developed at the,&nbsp;<a
                className="font-bold tracking-wide text-[#55cc32] hover:text-dark group transition-colors"
                href="https://theroguepixxel.com" target="_blank">rogue Pix<small
                className="group-hover:text-[#55cc32] text-dark">x</small>el</a></p></div>
        </>
    )
};

export default Footer;