import { HashLoader } from "react-spinners";
import mark from "./assets/images/mlmark.svg";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { motion } from "framer-motion";

const slider_settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 10000,
    dots: true,
    fade: false,
    infinite: true,
    nextArrow: <div></div>,
    pauseOnHover: false,
    prevArrow: <div></div>,
    slidesToScroll: 2,
    slidesToShow: 4,
    speed: 500,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToScroll: 3,
                slidesToShow: 3,

            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToScroll: 2,
                slidesToShow: 2,

            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,

            }
        }
    ]

}

export const Loader = () => <div className="fixed inset-0 bg-background flex items-center justify-center">
    <HashLoader size={54} color="#e10089" />
</div>;

export const contentProcessor = (content, index) => {
    switch (content.type) {
        case "paragraph":
            return <div className="w-full" key={index}>
                <div className="w-full block px-4 lg:px-0">
                    <p className="px-4 lg:px-12 py-8 prose max-w-none"
                        dangerouslySetInnerHTML={{ __html: content.value }} />
                </div>
            </div>;
        case "images_slider":
            return <div className={"w-full " + (content.white_background ? " bg-white" : " bg-transparent")}
                key={index}>
                <div className="w-full pb-10">
                    <Slider {...slider_settings}>
                        {
                            content.value.list.map((slide, i) => <div key={i}>
                                <div className="relative w-full text-center h-full px-4">
                                    <div className="w-full h-full cursor-pointer">
                                        <picture className="w-full">
                                            <source className="w-full object-cover"
                                                type="image/webp"
                                                srcSet={slide.image.webp} />
                                            <source className="w-full object-cover"
                                                type="image/jpeg"
                                                srcSet={slide.image.jpg} />
                                            <img className="w-full object-cover"
                                                src={slide.image.jpg}
                                                alt="" />
                                        </picture>
                                    </div>
                                </div>
                            </div>)
                        }
                    </Slider>
                </div>
            </div>;
    }
}

export const tabsContentProcessor = (content, index) => {

    const items = {
        visible: { opacity: 1, y: 0, transition: { ease: "easeIn", duration: 0.5 } },
        hidden: { opacity: 0, y: 150 },
    }

    const container = {
        visible: {
            transition: {
                staggerChildren: 0.2,
                ease: "easeInOut",
            }

        }
    }
    switch (content.type) {
        case "grid_block":
            return <motion.div
                variants={container} initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.1 }}
                className="flex-1 flex flex-wrap lg:pl-6 justify-evenly  lg:items-stretch" key={index}>
                {
                    content.value.list.map((item, i) => <motion.div variants={items}
                        className={"rounded-xl transition-all overflow-hidden flex w-full 2xl:w-[30%] items-start p-3 mb-4 " + (content.white_background ? " product_link" : " bg-white shadow")}>
                        <div className="flex-1" key={i}>
                            <h5 className="text-lg font-bold mb-1">{item.name}</h5>
                            <p className="text-sm" dangerouslySetInnerHTML={{ __html: item.details }} />
                        </div>
                    </motion.div>)
                }

                <p className="text-xs text-left w-full mt-4">{content.extra_content}</p>
            </motion.div>;
        case "table_block":
            return <div className="flex-1 flex flex-wrap lg:pl-6 justify-evenly  lg:items-stretch">
                <div className="my-6 w-full overflow-hidden relative max-w-full overflow-x-auto">
                    <table className="w-full table-auto ">
                        {
                            content.value.table.data.map((item, ir) => <tr
                                className={" text-dark" + (ir === 0 && content.value.table.first_row_is_table_header ? " font-bold" : " font-normal")}
                                key={ir}>
                                {
                                    item.map((td, id) => <td key={id}
                                        className={"px-2 lg:px-8 align-top py-2 mx-4" + ((ir === 0 && content.value.table.first_col_is_table_header) ? " font-bold" : " text-inherit")}>
                                        {td}
                                    </td>)
                                }
                            </tr>)
                        }
                    </table>
                </div>
            </div>;
        case "paragraph_with_grid_block":
            return <div className="flex-1 flex flex-wrap lg:pl-6 justify-evenly ">
                <div className="w-full lg:px-6">
                    <p className="border px-4 lg:px-12 py-5 lg:py-8 border border-dark rounded-lg prose prose-sm lg:prose-base max-w-none"
                        dangerouslySetInnerHTML={{ __html: content.value.paragraph }} />
                    <div className="w-full">
                        <h5 className="text-lg lg:text-xl 2xl:text-3xl font-primary font-bold mb-4 text-secondary mt-3">
                            Method & Application
                        </h5>
                        <div className="flex-1 flex flex-wrap justify-evenly items-stretch">
                            {
                                content.value.list.map((item, i) => <div key={i}
                                    className="rounded-xl transition-all overflow-hidden flex lg:w-[30%] items-start p-3 bg-white mb-4 shadow-md ">
                                    <div className="flex-1">
                                        <h5 className="text-lg font-bold mb-1">{item.name}</h5>
                                        <p className="text-sm" dangerouslySetInnerHTML={{ __html: item.details }} />
                                    </div>
                                </div>)
                            }
                            <p className="w-full text-xs text-left w-full mt-4"
                                dangerouslySetInnerHTML={{ __html: content.value.extra_content }} />
                        </div>
                    </div>
                </div>
            </div>;
        case "paragraph_list_block":
            return <div className="flex-1 flex flex-wrap lg:pl-6 justify-evenly ">
                <div className="w-full lg:px-6 space-y-6">
                    {
                        content.value.content.map((item, i) => <p key={i}
                            className="shadow-lg bg-white border px-4 lg:px-12 py-5 lg:py-8 border border-dark rounded-lg prose max-w-none"
                            dangerouslySetInnerHTML={{ __html: item }} />)
                    }
                    <p className="text-xs text-left w-full mt-4">{content.value.extra_content}</p></div>
            </div>;
    }
}

export const tabsProcessor = (tabs) => {
    return (
        <Tabs className="product_detail">
            <TabList>
                {
                    tabs.map((tab, i) => <Tab key={i}>
                        <div className="flex px-6 py-1.5 select-none">
                            <p className="text-base inline pl-2 font-primary font-bold">{tab.value.title}</p>
                        </div>
                    </Tab>)
                }

            </TabList>
            {
                tabs.map((tab, i) => <TabPanel key={i}>
                    <div
                        className={"w-full px-4 md:px-8 lg:px-12 2xl:px-32 py-5 " + (tab.value.white_background ? " bg-white" : " bg-background")}>
                        <div className="w-full flex flex-wrap px-4 lg:px-0">
                            <div className="w-full md:w-1/2 lg:w-1/3 lg:px-20">
                                <div className="w-12 lg:w-20">
                                    <img className="w-full" src={mark} alt="" />
                                </div>
                                <h5 className="text-lg lg:text-2xl 2xl:text-4xl font-primary font-bold mb-4 text-secondary mt-3">{tab.value.title}</h5>
                            </div>
                            {tabsContentProcessor(tab, i)}
                        </div>
                    </div>
                </TabPanel>)
            }
        </Tabs>
    )
}