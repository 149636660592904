import logo from "../assets/images/logo.svg";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";

const Header = (props) => {
    const mobile_menu = useRef(null)
    const { menu, solutions } = props
    const showMobileMenu = () => {
        gsap.to(mobile_menu.current, { y: "0%", opacity: 1, duration: .25 })
    }
    const closeMobileMenu = () => {
        gsap.to(mobile_menu.current, { y: "-100%", opacity: 0, duration: .25 })
    }
    return (
        <header className="relative w-full z-50">
            <div className="px-4 lg:px-8 flex pt-2 lg:pt-3 font-primary relative w-screen">
                <div className="w-24 md:w-30 lg:w-32 2xl:w-48 text-center py-2">
                    <Link to="/">
                        <img className="" src={logo} alt="" />
                    </Link>
                </div>
                <div className="flex flex-1 justify-end items-center cursor-pointer select-none lg:hidden relative"
                    onClick={showMobileMenu}>
                    <p className="border-l relative border-dark_light pl-4 material-icons-outlined text-3xl">
                        menu
                    </p>
                </div>
                <div
                    className="w-screen flex-1 flex flex-col justify-start lg:hidden bg-white navlinks h-screen fixed max-h-screen overflow-scroll left-0 -translate-y-full top-0 opacity-0 text-black"
                    ref={mobile_menu}>
                    <div className="w-full flex px-4 border-b border-white">
                        <div className="flex border-r-2 border-white py-2">
                            <div className="flex items-center">
                                <Link to="/">
                                    <img
                                        className="w-28 "
                                        src={logo}
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="flex lg:hidden flex-1 items-center justify-end">
                            <span className="material-icons-outlined text-dark text-4xl px-2 select-none" onClick={closeMobileMenu}>
                                close
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center">
                        <div className="flex pt-0 py-4 px-4">
                            {menu.menu.map((item, i) => {
                                switch (item.type) {
                                    case "page_link":
                                        return (
                                            <div className="flex-1 text-center pb-2 text-xl font-bold" key={i}>
                                                <Link
                                                    className=" hover:text-primary transition-colors"
                                                    to={item.value.slug}
                                                    target="_self"
                                                >
                                                    {item.value.title}
                                                </Link>
                                            </div>
                                        );
                                    case "relative_url":
                                        return (
                                            <div className="flex-1 text-center pb-2 text-xl font-bold" key={i}>
                                                <Link
                                                    className=" hover:text-primary transition-colors"
                                                    to={item.value.link}
                                                    target="_self"
                                                >
                                                    {item.value.override_title}
                                                </Link>
                                            </div>
                                        );
                                    default:
                                        return <div key={i}></div>;
                                }
                            })}
                        </div>
                        <div className="w-full flex flex-col items-center flex-wrap justify-center px-4"
                             id="navmenuacc">
                            {/*<Accordion allowZeroExpanded={true}>*/}
                            {/*    {*/}
                            {/*        solutions.solutions.map((item, i) =>*/}
                            {/*            <AccordionItem key={i}>*/}
                            {/*                <AccordionItemHeading>*/}
                            {/*                    <AccordionItemButton>*/}
                            {/*                        <p className="font-paragraph text-center uppercase text-lg font-bold hover:text-primary transition-colors py-1.5">*/}
                            {/*                            {item.name}*/}
                            {/*                        </p>*/}
                            {/*                    </AccordionItemButton>*/}
                            {/*                </AccordionItemHeading>*/}
                            {/*                <div className="flex flex-col w-full text-lg py-1 text-left">*/}
                            {/*                    {item.products.map((prod, i) =>*/}
                            {/*                        <AccordionItemPanel key={i}>*/}
                            {/*                            <div className="text-left">*/}
                            {/*                                <Link*/}
                            {/*                                    className="hover:text-primary transition-colors text-lg even:border-t py-1.5"*/}
                            {/*                                    onClick={closeMobileMenu}*/}
                            {/*                                    to={`/products/${item.slug}/${prod.slug}`}*/}
                            {/*                                >*/}
                            {/*                                    {prod.name}*/}
                            {/*                                </Link>*/}
                            {/*                            </div>*/}
                            {/*                        </AccordionItemPanel>)}*/}
                            {/*                </div>*/}
                            {/*                < /AccordionItem>*/}
                            {/*            )}*/}
                            {/*            </Accordion>*/}

                            <div className="flex flex-col w-full text-lg py-1 text-left">
                                {solutions.solutions.map((prod, i) =>
                                    <div key={i}>
                                        <div className="text-left">
                                            <Link
                                                className="hover:text-primary transition-colors text-lg even:border-t py-1.5"
                                                onClick={closeMobileMenu}
                                                to={`/products/${prod.slug}`}
                                            >
                                                {prod.name}
                                            </Link>
                                        </div>
                                    </div>)}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flex-1 hidden lg:flex flex-col px-12 2xl:px-16 2xl:pl-24">
                    <div className="w-full flex items-center justify-end text-xl xl:text-2xl xl:px-3 2xl:px-9">
                        <div className="text-center pb-2">
                            <Link
                                className=" hover:text-primary transition-colors px-3 xl:px-5"
                                to="/"
                                target="_self"
                            >
                                Home
                            </Link>
                        </div>
                        {menu.menu.map((item, i) => {
                            switch (item.type) {
                                case "page_link":
                                    return (
                                        <div className="flex" key={i}>
                                            <div className="text-center pb-2 px-3 xl:px-5">
                                                <Link
                                                    className=" hover:text-primary transition-colors"
                                                    to={item.value.slug}
                                                    target="_self"
                                                >
                                                    {item.value.title}
                                                </Link>
                                            </div>
                                            <div className="text-center pb-2 px-3 xl:px-5" key={i}>
                                                <Link
                                                    className=" hover:text-primary transition-colors"
                                                    to={`/gallery`}
                                                    target="_self"
                                                >
                                                    Gallery
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                case "relative_url":
                                    return (
                                        <div className="text-center pb-2 px-3 xl:px-5" key={i}>
                                            <Link
                                                className=" hover:text-primary transition-colors"
                                                to={item.value.link}
                                                target="_self"
                                            >
                                                {item.value.override_title}
                                            </Link>
                                        </div>
                                    );
                                default:
                                    return <div key={i}></div>;
                            }
                        })}
                    </div>
                    <div className="w-full flex items-center  justify-end px-3 xl:px-3 2xl:px-9">
                        {/* {solutions.solutions.map((item, i) => (
                            <div className="group relative px-5 border-t" key={i}>
                                <p className="font-paragraph uppercase font-lg 2xl:text-xl hover:text-primary transition-colors py-1.5 cursor-pointer">
                                    {item.name}
                                </p>
                                <div
                                    className="border flex flex-col w-full bg-white text-black absolute left-0 top-full text-lg max-h-0 group-hover:max-h-screen transition-all duration-150 bg-transparent py-2 opacity-0 group-hover:opacity-100 z-[100] group-hover:bg-white pointer-events-none group-hover:pointer-events-auto">
                                    {item.products.map((prod, ip) => (
                                        <div key={ip}
                                            className="text-center px-1.5 border-b border-b-[#acacac] border-t border-t-[#dddddd] first:border-t-0 last:border-b-0 border-opacity-30">
                                            <Link
                                                className=" hover:text-primary transition-colors text-base lg:text-lg xl:text-xl py-1 lg:py-1.5 xl:py-2 block"
                                                to={`/products/${item.slug}/${prod.slug}`}
                                            >
                                                {prod.name}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))} */}
                        {
                            solutions.solutions.map((product, i) => <div className="group relative px-5 border-t" key={i}>
                                <Link
                                    className="font-paragraph uppercase font-lg 2xl:text-xl hover:text-primary transition-colors py-1.5 cursor-pointer"
                                    to={`/products/${product.slug}`}>
                                    {product.name}
                                </Link>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </header>
    );
};
export default Header;
