import * as React from "react";
import { useEffect, useState } from "react";
import api from "../api";
import { useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { PhotoAlbum } from "react-photo-album";


export default function App() {
    const [open, setOpen] = React.useState(false);
    const [index, setIndex] = React.useState(-1);

    const [gallery, setGallery] = useState(null);
    let { gallery_slug } = useParams();

    async function fetchGalleryData() {
        const response = await api.get(`/api/pages/gallery/`);
        await setGallery(response.data);
    }

    useEffect(() => {
        fetchGalleryData();
    }, []);


    return (
        <div className="pt-8 xl:pt-20 mx-4 xl:mx-40  xl:pb-16">
            <h1 className="text-4xl md:pt-10 md:pb-2 xl:pb-3 xl:text-6xl pt-8 py-0 text-center font-primary">Gallery</h1>
            {gallery !== null ? <div className="">
                <PhotoAlbum
                    breakpoints={[320, 640, 768, 1280]}
                    layout="rows"
                    photos={gallery.gallery}
                    spacing={20}
                    onClick={({ index: current }) => setIndex(current)}
                    renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                        <div className="group overflow-hidden" style={{ position: "relative", ...wrapperStyle }}>
                            {renderDefaultPhoto({ wrapped: true })}
                            {photo.caption !== "" && (
                                <div
                                    className="md:-bottom-full text-xs text-center leading-tight md:text-base xl:text-lg bottom-0 md:group-hover:bottom-0 transition-all font-primary text"
                                    style={{
                                        position: "absolute",
                                        overflow: "hidden",
                                        backgroundColor: "rgba(255 255 255/ .6)",
                                        padding: 8,
                                    }}
                                >
                                    {photo.caption}
                                </div>
                            )}
                        </div>
                    )}
                />


                <Lightbox
                    index={index}
                    slides={gallery.gallery}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                />
            </div> : <div></div>}
        </div>
    );
}
