import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import api from "../api";
import {Loader} from "../utils";
import mark from '../assets/images/mlmark.svg'
import ContactForm from "../components/ContactForm";
import {AnimatePresence, motion} from "framer-motion";

export default function AboutUs() {
    const [page, setPage] = useState(null);
    let {page_slug} = useParams();

    async function fetchPageData() {
        const response = await api.get(`/api/pages/about-us/`);
        await setPage(response.data);
    }

    const title = {
        visible: {opacity: 1, y: 0},
        hidden: {opacity: 0.5, y: 80},
    }
    const fadeout = {
        visible: {opacity: 1,},
        hidden: {opacity: 0}
    }

    const item = {
        visible: {opacity: 1, y: 0, transition: {ease: "easeIn", duration: 0.35}},
        hidden: {opacity: 0, y: 150},
    }

    const container = {
        visible: {
            transition: {
                staggerChildren: 0.1,
                ease: "easeInOut",
            }

        }
    }
    useEffect(() => {
        fetchPageData();
    }, []);
    return (
        <div className="w-full">
            {
                page === null ? <Loader/> : <AnimatePresence>
                    <motion.div initial={{scale: 0.95, opacity: 0}}
                                animate={{scale: 1, opacity: 1,}}
                                exit={{scale: 0.95, opacity: 0}}
                                className="origin-top w-full">
                        <div
                            className="w-full flex flex-wrap py-4  lg:py-8 2xl:py-12 2xl:pb-20  items-center px-4 md:px-8 lg:px-12 2xl:px-32 bg-white min-h-screen">
                            <div className="w-full lg:w-1/3">
                                <div className="prose-xl prose-h2:font-bold prose-h2:font-primary prose-h2:text-5xl"
                                     dangerouslySetInnerHTML={{__html: page.featured_section_title}}/>
                            </div>
                            <div className="flex-1 px-6">
                                <picture className="drop-shadow-md">
                                    <source className="w-full " type="image/webp"
                                            srcSet={page.featured_section_image.webp}/>
                                    <source className="w-full " type="image/jpeg"
                                            srcSet={page.featured_section_image.original}/>
                                    <img className="w-full"
                                         src={page.featured_section_image.original} alt=""/>
                                </picture>
                            </div>
                        </div>
                        <motion.div
                            variants={title}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 0.1}}
                            transition={{duration: 0.5}}
                            className="w-full flex py-4 lg:py-10 2xl:py-20 items-center px-4 md:px-8 lg:px-12 2xl:px-32">
                            <div className="prose prose-base lg:prose-xl max-w-none "
                                 dangerouslySetInnerHTML={{__html: page.intro_paragraph}}/>
                        </motion.div>
                        <div
                            className="w-full flex flex-col py-4 lg:py-10 2xl:py-20 items-center px-4 md:px-8 lg:px-12 2xl:px-32 bg-white">
                            <motion.h3
                                variants={title}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{once: true, amount: 0.1}}
                                transition={{duration: 0.5}}
                                className="text-2xl lg:text-3xl 2xl:text-5xl text-secondary font-primary font-bold lg:tracking-wide">Message
                                from the chairman
                            </motion.h3>
                            <motion.div
                                variants={fadeout}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{once: true, amount: 0.5}}
                                transition={{duration: 1}}
                                className="w-full flex flex-wrap pt-16">
                                <div className="w-full lg:w-2/5 px-10 lg:px-20">
                                    <div
                                        className="w-full border-4 lg:border-8 border-secondary rounded-2xl overflow-hidden relative">
                                        <picture className="">
                                            <source className="w-full " type="image/webp"
                                                    srcSet={page.message_section_image.webp}/>
                                            <source className="w-full " type="image/jpeg"
                                                    srcSet={page.message_section_image.original}/>
                                            <img className="w-full"
                                                 src={page.message_section_image.original}
                                                 alt=""/>
                                        </picture>
                                        <div className="bg-white text-center"><h3 className="text-lg font-bold">Mr.
                                            Kuldeep
                                            Sahu</h3><p className="text-sm">Managing Director</p></div>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className="prose prose-sm md:prose-base lg:prose-lg max-w-none"
                                         dangerouslySetInnerHTML={{__html: page.message_paragraph}}/>
                                </div>
                            </motion.div>
                            <div className="w-full flex flex-col py-4 lg:py-10 2xl:py-20">
                                <motion.div
                                    variants={container} initial="hidden"
                                    whileInView="visible"
                                    viewport={{once: false, amount: 0.5}}
                                    className="w-full flex flex-col ">
                                    {
                                        page.content_section[0].value.map((item, i) => <motion.div
                                            key={i}
                                            variants={item}
                                            className="w-full lg:w-3/5 flex flex-col lg:flex-row bg-white odd:rounded-l-md even:rounded-r-md shadow-lg py-8 lg:py-12 px-4 lg:px-24 mb-10 odd:self-end even:self-start">
                                            <div className="w-12 lg:w-20 ">
                                                <img className="w-full" src={mark} alt=""/>
                                            </div>
                                            <div className="lg:px-6"><h4
                                                className="prose-lg md:text-2xl lg:text-3xl font-bold mb-2 lg:mb-4">{item.name}</h4>
                                                <div className="prose prose-sm lg:text-base max-w-none"
                                                     dangerouslySetInnerHTML={{__html: item.details}}/>
                                            </div>
                                        </motion.div>)
                                    }

                                </motion.div>
                            </div>
                        </div>
                        <ContactForm/>
                    </motion.div>
                </AnimatePresence>

            }
        </div>);
}