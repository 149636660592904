import React, { Component } from "react";
import Slider from "react-slick";

export default class PauseOnHover extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true
        };
        return (
            <div>
                <h2>Pause On Hover</h2>
                <Slider {...settings}>



                </Slider>
            </div>
        );
    }
}