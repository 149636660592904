import React, {useState, useEffect, useRef} from "react";
import SimpleSlider from '../components/SimpleSlider'
import {Loader} from "../utils";
import ContactForm from "../components/ContactForm";
import Cards from "../components/Cards"
import SecSlider from "../components/SecSlider"
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {AnimatePresence, motion, useScroll} from "framer-motion"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import logo from "../assets/images/logo.svg";
import {gsap} from "gsap";

gsap.registerPlugin(ScrollTrigger);


const Home = () => {

    const [home, setHome] = useState(null);


    async function fetchHomeData() {
        const response = await fetch("https://markplus.theroguepixxel.com/api/pages/mark-plus/");
        setHome(await response.json());
    }

    useEffect(() => {
        fetchHomeData();
    }, []);

    useEffect(() => {
    }, [home]);

    if (!home) {
        return <Loader/>;
    }

    const title = {
        visible: {opacity: 1, y: 0},
        hidden: {opacity: 0.5, y: 80},
    }
    const whyanimate ={
        visible: {opacity:1,},
        hidden:{opacity:0}
    }


    return (
        <AnimatePresence>
            <motion.div
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.95, opacity: 0 }}
                className="origin-top"
            >
                <div className="relative w-full text-center h-full">
                    <div className="w-full h-full">
                        <SimpleSlider banners={home.page_banners}/>
                    </div>
                    <div
                        className="w-full flex flex-col py-4 lg:py-10 2xl:py-20 items-center px-4 md:px-8 lg:px-12 2xl:px-32 bg-white">
                        <motion.h3
                            className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-secondary font-primary font-bold lg:tracking-wide "
                            variants={title}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 0.1}}
                            transition={{duration: 0.5}}>
                            {home.about_section_title}
                        </motion.h3>
                        <div className="w-full flex flex-col md:flex-row pt-2 md:pt-8">
                            <div className="flex-1">
                                <motion.p className="max-w-none prose prose-sm lg:prose-lg leading-loose text-center"
                                          variants={title}
                                          initial="hidden"
                                          whileInView="visible"
                                          viewport={{once: true, amount: 0.8}}
                                          transition={{duration: 0.5}}
                                          dangerouslySetInnerHTML={{__html: home.about_paragraph}}/>
                            </div>
                            {/*<div className="w-3/4 mx-auto md:w-1/3 px-6">*/}
                            {/*  <picture className="">*/}
                            {/*    <source*/}
                            {/*      className="w-full "*/}
                            {/*      type="image/webp"*/}
                            {/*      srcSet={home.about_image.webp}*/}
                            {/*    />*/}
                            {/*    <source*/}
                            {/*      className="w-full "*/}
                            {/*      type="image/jpeg"*/}
                            {/*      srcSet={home.about_image.original}*/}
                            {/*    />*/}
                            {/*    <img*/}
                            {/*      className="w-full"*/}
                            {/*      src={home.about_image.original}*/}
                            {/*      alt=""*/}
                            {/*    />*/}
                            {/*  </picture>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="w-full flex flex-col pt-4 lg:pt-10 2xl:pt-20 items-center">
                        <motion.h3 className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-primary font-primary font-bold tracking-wide " variants={title}
                                   initial="hidden"
                                   whileInView="visible"
                                   viewport={{once: true, amount: 0.1}}
                                   transition={{duration: 0.5}}>
                            {home.products_section_title}
                        </motion.h3>
                        <Cards solution={home.solutions}/>
                        {/*<BrowserView>*/}
                        {/*  */}
                        {/*</BrowserView>*/}
                        {/*<MobileView>*/}
                        {/*  <div className="w-full flex flex-col hidden">*/}
                        {/*    {*/}
                        {/*      home.solutions.map((solution, i) => <div key={i} className="w-full px-4 md:px-8 lg:px-12 2xl:px-32 even:bg-white pt-4">*/}
                        {/*        <h3 className="text-xl lg:text-2xl 2xl:text-4xl font-primary font-bold mb-2">*/}
                        {/*          {solution.name}*/}
                        {/*        </h3>*/}
                        {/*        <div className="w-full flex flex-col">*/}
                        {/*          <div className="w-full">*/}
                        {/*            <p className="prose prose-sm max-w-none leading-normal"*/}
                        {/*              dangerouslySetInnerHTML={{ __html: solution.intro }} />*/}
                        {/*          </div>*/}
                        {/*          <div className="w-full flex flex-wrap px-2 justify-evenly items-start ">*/}
                        {/*            {*/}
                        {/*              solution.products.map((product, i) => <Link*/}
                        {/*                key={i}*/}
                        {/*                to={`/products/${solution.slug}/${product.slug}`}*/}
                        {/*                className="h-min rounded-xl transition-all overflow-hidden flex w-full sm:w-[45%] items-start p-3 product_link">*/}
                        {/*                <div className="px-2">*/}
                        {/*                  <img className="w-8" src={mark} alt="" />*/}
                        {/*                </div>*/}
                        {/*                <div className="flex-1">*/}
                        {/*                  <h5 className="text-base font-bold mb-1">*/}
                        {/*                    {product.name}*/}
                        {/*                  </h5>*/}
                        {/*                  <p className="text-xs"*/}
                        {/*                    dangerouslySetInnerHTML={{ __html: product.intro }} />*/}
                        {/*                </div>*/}
                        {/*              </Link>)*/}
                        {/*            }*/}
                        {/*          </div>*/}
                        {/*        </div>*/}
                        {/*      </div>)*/}
                        {/*    }*/}
                        {/*  </div>*/}
                        {/*</MobileView>*/}
                    </div>
                    <div className="px-10 my-12 flex">
                        <div className="w-full lg:w-1/5 flex items-center justify-center">
                            <img className="w-48" src={logo} alt=""/>
                        </div>
                        <div className="w-full lg:w-3/4">
                            <SecSlider slider={home.home_slider}/>
                        </div>

                    </div>
                    <motion.div
                        variants={whyanimate}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{once: true, amount: 0.6}}
                        transition={{duration: 1}}

                        className="w-full flex flex-col py-4 lg:py-10 2xl:py-20 items-center px-4 md:px-8 lg:px-12 2xl:px-32">
                        <h3

                            className="text-2xl lg:text-3xl 2xl:text-5xl text-secondary font-primary font-bold lg:tracking-wide ">
                            {home.why_section_title}
                        </h3>
                        <div className="w-full flex flex-col lg:flex-row pt-4 lg:pt-16">
                            <div className="flex-1 2xl:pr-32">
                                <p className="w-full md:w-4/5 prose prose-lg lg:prose-xl prose-h1:font-primary prose-h2:font-primary prose-h3:font-primary prose-h4:font-primary prose-h5:font-primary max-w-none leading-normal"
                                   dangerouslySetInnerHTML={{__html: home.why_paragraph}}/>
                            </div>
                            <div className="w-full lg:w-2/5 mt-6 lg:mt-0">
                                <Accordion>
                                    {
                                        home.why_list.map((item, i) => <AccordionItem key={i}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <p className="text-lg md:text-xl 2xl:text-2xl font-primary font-bold">
                                                        {item.title}
                                                    </p>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <hr/>
                                                <p className="pl-4 ml-2 md:ml-6 lg:ml-8 relative my-2 text-sm md:text-base"
                                                   dangerouslySetInnerHTML={{__html: item.description}}/>
                                            </AccordionItemPanel>
                                        </AccordionItem>)
                                    }
                                </Accordion>

                            </div>
                        </div>
                    </motion.div>

                </div>
                <ContactForm/>
            </motion.div>
        </AnimatePresence>
    );
};

export default Home;
