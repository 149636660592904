import React, {Component, useState, useEffect} from "react";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import {motion} from "framer-motion"


const SimpleSlider = (props) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const [activeSlide2, setActiveSlide2] = useState(0)

    const overlay = {
        active: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.08,
                delayChildren: 0.3,
                delay: 0.5,
                ease: [.77, 0, .175, 1]
            },
        },
        notActive: {opacity: 0},
    }

    const items = {
        active: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.25,
                ease: [.77, 0, .175, 1]
            }
        },
        notActive: {opacity: 0, y: 50},
    }


    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnHover: false,
        beforeChange: (current, next) => setActiveSlide(next),
        afterChange: current => setActiveSlide2(current)
    };

    return (
        <div id="homeslider" className="relative">
            <Slider {...settings} >
                {props.banners.map((img, i) => (
                    <motion.div key={i} animate={activeSlide2 === i ? "active" : "notActive"}>
                        <div className="relative w-full text-center h-full">
                            <div className="md:hidden ">
                                <picture className="w-auto h-screen">
                                    <source srcSet={img.mobile_banner?.webp_full} type="image/webp"/>
                                    <source srcSet={img.mobile_banner?.full} type="image/jpeg"/>
                                    <img className="w-auto  object-cover" src={img.mobile_banner?.full} alt=""/>
                                </picture>
                            </div>
                            <div className="w-full h-full hidden md:block">
                                <picture className="w-full">
                                    <source srcSet={img.image.webp_banner} type="image/webp"/>
                                    <source srcSet={img.image.banner} type="image/jpeg"/>
                                    <img className="w-full  object-cover" src={img.image.banner} alt=""/>
                                </picture>
                            </div>
                            {
                                (img.title || img.caption || img.overlay_image) ? <motion.div
                                    variants={overlay}
                                    className="absolute inset-0 z-10 w-full h-full bg-white bg-opacity-20 py-3 lg:py-12 px-3 md:px-12 lg:px-16 2xl:px-36 pt-28 flex flex-col justify-start md:justify-center z-20">
                                    <div className="w-full flex flex-col md:flex-row md:-mt-20 lg:-mt-0 h-full lg:h-[initial] items-center">
                                        <motion.div className="w-full md:w-1/4 md:px-10" variants={items}>
                                            <img className="drop-shadow-xl w-2/5 md:w-full mx-auto"
                                                 src={img.overlay_image?.original} alt=""/>
                                        </motion.div>
                                        <div
                                            className="flex flex-col items-end flex-1 text-center md:text-right h-full justify-center xl:-mt-20">
                                            <div className="w-full w-full md:w-3/4">
                                                {
                                                    img.title && <motion.h2
                                                        variants={items}
                                                        className="font-semibold font-display text-4xl 2xl:text-5xl text-white mb-4">{img.title}</motion.h2>
                                                }
                                                {
                                                    img.caption && <motion.p
                                                        variants={items}
                                                        className=" font-display text-3xl font-light lg:text-3xl 2xl:text-5xl font-medium 2xl:leading-normal tracking-wide text-[#ea3e04] mb-3 md:mb-6 2xl:mb-10 italic">
                                                        {
                                                            img.caption
                                                        }
                                                    </motion.p>
                                                }
                                                {
                                                    img.cta.length !== 0 && <motion.div variants={items}>
                                                        <Link
                                                            className="font-primary text-2xl 2xl:text-4xl font-semibold bg-white rounded text-secondary px-6 py-2 font-bold hover:text-white hover:bg-secondary transition-colors shadow"
                                                            to={img.cta[0].value}>
                                                            {
                                                                img.cta_text
                                                            }
                                                        </Link>
                                                    </motion.div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </motion.div> : null
                            }
                        </div>
                    </motion.div>
                ))}
            </Slider>
        </div>
    );
};

export default SimpleSlider;